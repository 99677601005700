export default [
  {
    header: 'About Us',
    items: [
      {
        path: '/',
        label: 'Message Us',
      },
      {
        path: '/',
        label: 'Customer Support',
      },
      {
        path: '/',
        label: 'About Us',
      },
      {
        path: '/',
        label: 'Copyright',
      },
      // {
      //   path: '/',
      //   label: 'New Campaign',
      // },
    ],
  },
  {
    header: 'Our Information',
    items: [
      {
        path: '/',
        label: 'Legal Policy ',
      },
      {
        path: '/',
        label: 'Privacy Policy',
      },
      {
        path: '/',
        label: 'Terms & Conditions',
      },
      {
        path: '/',
        label: 'Site Map',
      },
      // {
      //   path: '/',
      //   label: 'Availiability Hours',
      // },
    ],
  },
  {
    header: 'My Account',
    items: [
      {
        path: '/',
        label: 'Inquiries',
      },
      {
        path: '/',
        label: 'Social media ',
      },
      {
        path: '/',
        label: 'directories',
      },
      // {
      //   path: '/',
      //   label: 'Images & B-roll',
      // },
      // {
      //   path: '/',
      //   label: 'Permissions',
      // },
    ],
  },
  // {
  //   header: 'Policy',
  //   items: [
  //     {
  //       path: '/',
  //       label: 'Application security',
  //     },
  //     {
  //       path: '/',
  //       label: 'Software principles',
  //     },
  //     {
  //       path: '/',
  //       label: 'Unwanted software policy',
  //     },
  //     {
  //       path: '/',
  //       label: 'Responsible supply chain',
  //     },
  //   ],
  // },
];
