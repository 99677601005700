/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui';
import SectionHeader from 'components/section-header';
import Accordion from 'components/accordion/accordion';
const faqs = [
  {
    title: 'How to download from the google or app store ?',
    contents: (
      <div>
        Get your app downloaded at the Google play store. We are going to launch live 'coming' soon at the 
        play store (not available for the app store). Keep on following us here on the site or on Twitter to get the final release date. 
      </div>
    ),
  },
  {
    title: 'App installation, how to particpate in beta version?',
    contents: (
      <div>
        We have a beta version at the play store that can be downloaded. If you are interested, send us a message and we will
        get back to you about testing and how to download the beta version of the app.
        
      </div>
    ),
  },
  {
    title: `Website, how to improve?`,
    contents: (
      <div>
        We have updated our website, hope that you enjoy it. We will be making more improvements in the coming months;
        To make it easier to navigate and use. If any questions about the website, leave us a message.
      </div>
    ),
  },
  {
    title: `New updates fixed all bug and issues`,
    contents: (
      <div>
        If you'd like to contact us for any of the bug or issues updates, leave us a message with the description of the app bugs and issues and we will
        try to fix them as fast as we possibly can. 
      </div>
    ),
  },
    {
      title: `Hire Us`,
      contents: (
        <div>
          If you'd like to contact us for any project, check out at fiverr -  <a href=" https://www.fiverr.com/s2/32d180fc55" class="button button--link">  "New Digital Media" </a>  or send us direct message with 
          a return address. Excellent for students, professionals, businesses and startups! 
        </div>
      ),
  },
];
export default function Faq() {
  return (
    <section sx={{ variant: 'section.faq' }}>
      <Container>
        <SectionHeader
          title="Frequently asked question"
          slogan="Get your question answer"
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
      </Container>
    </section>
  );
}
