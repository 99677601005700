/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Flex, Box, Heading, Text, Button, Image } from 'theme-ui';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ModalVideo from 'react-modal-video';
import { Link } from 'components/link';
import Img from 'gatsby-image';
import { FaPlayCircle } from 'react-icons/fa';
import BannerBG from 'assets/bannerBg.png';
import bannerImage from 'assets/images/banner-thumb3.png';
import bannervid from 'assets/images/Bannervid0120121.mp4';
import client1 from 'assets/sponsor/paypal.svg';
import client2 from 'assets/sponsor/google.svg';
import client3 from 'assets/sponsor/dropbox.svg';

const data = [
  {
    id: 1,
    path: '#',
    image: client1,
    title: 'paypal',
  },
  {
    id: 2,
    path: '#',
    image: client2,
    title: 'google',
  },
  {
    id: 3,
    path: '#',
    image: client3,
    title: 'dropbox',
  },
];


export default function Banner() {
  // Graphql image query
  const dataThumb = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            
          }
        }
      }
    }
  `);
  // Popup video status
  const [videoOpen, setVideoOpen] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setVideoOpen(true);
  };

  
  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        <Box sx={styles.banner.contentBox}>
          
          <Heading as="h1" variant="heroPrimary">
            Experience your ultimate organizational mobile application
          </Heading>
          <Text as="p" variant="heroSecondary">
            Get your time and organization back with the ultimate smart notepad to take notes on the go, at any time for any 
            clinical event.
          </Text>
          <Flex>
          <ModalVideo
                channel="youtube"
                isOpen={videoOpen}
                videoId="Uve3tnAn2pQ"
                onClose={() => setVideoOpen(false)}
              />
              <Button
                variant="textButton"
                aria-label="Watch Video"
                onClick={handleClick}
              >
                <FaPlayCircle /> Watch Video
              </Button>
            <Button variant="whiteButton" aria-label="Get Started">
              Get Started
            </Button>
            <>
              
            </>
          </Flex>
          {/* <Flex sx={styles.sponsorBox}>
            <Text sx={styles.sponsorTitle}>Sponsored by:</Text>
            <Box sx={styles.sponsorBox.sponsor}>
              {data.map((item, index) => (
                <Link path={item.path} key={`client-key${index}`}>
                  <Image src={item.image} alt={item.title} />
                </Link>
              ))}
            </Box>
          </Flex> */}
        </Box>
        {/* <Box sx={styles.bannerImagex}>
          <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} />
        </Box> */}
          
        
        <video
                 sx={styles.bannerImage.banner2}
                  height="100%"
                  width="100%"
                  loop
                  muted
                  autoPlay
                  
               
                >
                  <source
                    src={bannervid}
                    type="video/mp4"
                    
                  />
                </video>
         <Image src={bannerImage} sx={styles.bannerImage1} alt="banner moc" />       
      </Container>
     
    </section>
    
  );
}

const styles = {
 
  bannerImage1: {
    display: ['none', null, null, 'block'], 
    // position: 'absolute',
    objectPosition: 'top',
    justifyContent: 'center',
    ml: [0, null, null, '-110px', '-115px', '-150px', '-210px', '-270px'],
    mr: [0, null, null, '-145px', '-160px', '-180px', '-220px', '-290px'],
    mt: [0, null, null, '40px', 4, 7, 0],
    mb: [0, null, null, null, '-45px', '-70px', null, '-115px'],
    overflow: 'hidden',
    textAlign: 'right',
    width: '80%',
   
    opacity: '0.98',
  
  },

  bannerImage: {
    display: ['none', null, null, 'block'],
    justifyContent: 'center',
    ml: [0, null, null, '-110px', '-115px', '-150px', '-210px', '-270px'],
    mr: [0, null, null, '-145px', '-160px', '-180px', '-220px', '-290px'],
    mt: [0, null, null, '40px', 4, 7, 0],
    mb: [0, null, null, null, '-45px', '-70px', null, '-115px'],
    overflow: 'hidden',
    textAlign: 'right',
    width: '80%',
    // position: 'absolute',
    // opacity: '0.98',
    banner2: {
      overflow: ['hidden', 'initial', null, 'hidden'],
      height: "70%",
      width: "80%",
      position: 'absolute',
      opacity: '0.10',
    
      },
  },
 
  banner: {
    overflow: ['hidden', 'initial', null, 'hidden'],
    backgroundImage: `url(${BannerBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    borderBottomRightRadius: [100, 150, null, null, null, 250],
    pt: ['150px', null, null, null, null, null, '140px', '130px'],
    pb: ['100px', null, null, '110px', null, 10, '150px'],
    backgroundColor: 'primary',
    container: {
      display: 'flex',
  
    },

   
   
    contentBox: {
      width: ['100%', null, '85%', '55%', '50%', '55%'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexShrink: 0,
      pt: [0, null, null, null, null, null, 5, 7],
    
    },
    imageBox: {
      display: ['none', null, null, 'block'],
      justifyContent: 'center',
      ml: [0, null, null, '-110px', '-115px', '-150px', '-210px', '-270px'],
      mr: [0, null, null, '-145px', '-160px', '-180px', '-220px', '-290px'],
      mt: [0, null, null, '40px', 4, 7, 0],
      mb: [0, null, null, null, '-45px', '-70px', null, '-115px'],
      overflow: 'hidden',
      textAlign: 'right',
      width: '100%',
    },
  },

  sponsorTitle: {
    color: 'white',
    fontSize: [1, 2],
    opacity: 0.6,
    pr: 20,
    flexShrink: 0,
    pb: [2, null, 0],
  },
  sponsorBox: {
    pt: ['35px', null, null, null, null, '45px'],
    flexDirection: ['column', null, 'row'],
    sponsor: {
      display: 'flex',
      alignItems: 'center',
      '> a': {
        mr: [5, null, null, 4, 6],
        display: 'flex',
        '&:last-of-type': {
          mr: 0,
        },
      },
    },
  },

};
